<template>
    <div>
      <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
        <b-col cols="1">
          <h3>You do not have permissions for this page</h3>
        </b-col>
      </b-row>
      <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
        <b-col cols="1">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </b-col>
      </b-row>
      <b-row class="text-white bg-kpmg-blue" v-if="!loading && permissions.read">
        <b-col cols="2" class="my-3">
          <h1 class="kstyle title mb-0">{{pageName}}</h1>
          <div>{{rowsShow.length}} of {{rows.length}}</div>
        </b-col>
        <b-col cols="8" align-self="center" align-v="center" class="search text-center cols justify-content-md-center">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
              <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="text-white bg-kpmg-blue pb-4" v-if="!loading && permissions.read">
        <b-col align-v="center">
            <a v-for="row in select" :key="'select-id' + row.property" class="mr-2 mb-3 mt-0 text-center" flex>
              <b-button v-b-toggle="'sidebar-' + row.property" class="filter-button">{{row.name}} <b-badge v-if="$data[row.selected].length != 0" variant="light">{{$data[row.selected].length}}</b-badge></b-button>
            </a>
            <b-btn class="filter-button" @click="filterReset">reset filters</b-btn>
            <b-button class="filter-button ml-2" v-if="permissions.add" :to="{ name: 'FormNew', params: {model: model }}">add</b-button>
        </b-col>
        <div v-for="row in select" :key="'select-id' + row.property" class="mr-2 mb-3">
          <b-sidebar width="50%" :id="'sidebar-' + row.property" :title="row.property.charAt(0).toUpperCase() + row.property.slice(1)" backdrop backdrop-variant="transparent" shadow>
            <div class="px-3 py-2 text-center" align-self="center" align-v="center">
              <b-button-group b-button-group class="mt-3 text-center">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setSelection('all', row.property)">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setSelection('none', row.property)">none</b-button>
              </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="$data[row.selected]"
                      :options="$data[row.options]"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                  ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
        </div>
      </b-row>
      <b-row class="text-white bg-kpmg-blue mt-0 pb-4" v-if="!loading && permissions.read && dateFilter">
        <b-col align-self="center" cols="8" class="cols text-center" align-v="center">
          <b-form inline>
            start date: <b-form-input class="mx-2" type="date" v-model="dateStart"></b-form-input>
            end date: <b-form-input class="mx-2" type="date" v-model="dateEnd"></b-form-input>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="!loading && permissions.read">
        <b-col>
          <div class="mt-4" style="font-size: 1.2em">
            <b-nav pills>
              <span v-for="link in nav" :key="'link'+link.name">
                <b-nav-item :to="{ name: link.name }" exact exact-active-class="active">{{link.text}}</b-nav-item>
              </span>
              <links-dropdown text="Reference data" :links="referenceData" />
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-row v-show="!loading && permissions.read">
        <router-view></router-view>
      </b-row>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import LinksDropdown from '@/components/LinksDropdown.vue'

export default {
  name: 'FilterInterface',
  props: ['dateFilter', 'model', 'nav', 'pageName', 'rows', 'staticDataProperties', 'staticData', 'loading', 'permissions', 'perPage', 'referenceData', 'searchProperties', 'sortDirectionProp', 'sortPropertyProp', 'sortPropertyOptionsProp'],
  components: {
    LinksDropdown
  },
  computed: {
    rowsShow: function () {
      const sortProperty = this.$store.state.sortProperty
      const sortDirection = this.$store.state.sortDirection
      let rows = []
      rows = this.rows
      _.each(this.select, row => {
        if (row.direct) {
          if (this[row.selected].length > 0) {
            rows = _.filter(rows, x => {
              let result = false
              _.each(this[row.selected], item => {
                if (x[row.property] === item) {
                  result = true
                }
              })
              return result
            })
          }
        }
        if (row.graph) {
          if (this[row.selected].length > 0) {
            rows = _.filter(rows, x => {
              let result = false
              if (Array.isArray(x[row.graph])) {
                _.each(this[row.selected], item => {
                  if (_.find(x[row.graph], ['id', item])) {
                    result = true
                  }
                })
              } else {
                _.each(this[row.selected], item => {
                  if (x[row.graph].id === parseInt(item)) {
                    result = true
                  }
                })
              }
              return result
            })
          }
        }
      })
      if (this.filter.length > 1) {
        rows = rows.filter(x => {
          let term = x.name + ' ' + x.objective + ' ' + x.description
          if (this.searchProperties) {
            term = ''
            this.searchProperties.forEach(property => {
              term = term + x[property] + ' '
            })
          }
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      if (this.dateFilter) {
        rows = rows.filter(x => {
          return moment(x[this.dateFilter.property]).isSameOrAfter(this.dateStart)
        })
        rows = rows.filter(x => {
          return moment(x[this.dateFilter.property]).isSameOrBefore(this.dateEnd)
        })
      }
      rows = _.orderBy(rows, [sortProperty], [sortDirection])
      this.$store.commit('setRowsShow', rows)
      return rows
    }
  },
  created: function () {
    this.$store.commit('setRows', this.rows)
    this.$store.commit('setRowsShow', this.rows)
    this.$store.commit('setSortDirection', this.sortDirectionProp)
    this.$store.commit('setSortProperty', this.sortPropertyProp)
    this.$store.commit('setSortPropertyOptions', this.sortPropertyOptionsProp)
    _.each(this.staticDataProperties, property => {
      const selected = property.property + 'Selected'
      const options = property.property + 'Options'
      this.$data[options] = this.staticData[property.property].map(x => {
        return { value: x.id, text: x.name }
      })
      const selectRow = {
        name: property.name,
        direct: property.direct,
        graph: property.graph,
        property: property.property,
        selected: selected,
        options: options
      }
      this.select.push(selectRow)
    })
  },
  data () {
    const data = {
      dateStart: moment().subtract(10, 'year').format('YYYY-MM-DD'),
      dateEnd: moment().add(1, 'days').format('YYYY-MM-DD'),
      filter: '',
      select: []
    }
    _.each(this.staticDataProperties, property => {
      const selected = property.property + 'Selected'
      const options = property.property + 'Options'
      data[selected] = []
      data[options] = []
    })
    return data
  },
  methods: {
    setSelection: function (button, selectionName) {
      if (button === "none") {
        this[selectionName + 'Selected'] = []
      }
      if (button === 'all') {
        this[selectionName + 'Selected'] = []
        _.each(this[selectionName + 'Options'], x => {
          this[selectionName + 'Selected'].push(x.value)
        })
      }
    },
    filterReset: function () {
      _.each(this.staticDataProperties, property => {
        const selected = property.property + 'Selected'
        this.$data[selected] = []
      })
      this.filter = ''
    }
  }
}
</script>

<style>
body {
  padding-top: 100px;
}
</style>
