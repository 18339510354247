<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <div v-if="!loading && permissions.read">
      <filter-interface
        :model="model"
        :nav="nav"
        :pageName="pageName"
        :rows="rows"
        :searchProperties="searchProperties"
        :staticDataProperties="staticDataProperties"
        :staticData="staticData"
        :loading="loading"
        :permissions="permissions"
        :sortDirectionProp="sortDirection"
        :sortPropertyProp="sortProperty"
        :sortPropertyOptionsProp="sortPropertyOptions"
      />
    </div>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import FilterInterface from '@/components/FilterInterface.vue'

export default {
  name: 'SourcesLayout',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.sources
      },
      set (payload) {
        this.$store.commit('setSources', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.permissions = {
      read: ac.can(this.user.acgroups).readAny('source').granted,
      add: false
    }
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'source',
      nav: [
        { name: 'SourcesTableRows', text: 'Sources' },
        { name: 'SourcesTableDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Sources',
      permissions: {
        add: false,
        read: false
      },
      searchProperties: ['source', 'publisher', 'countryName', 'parser'],
      staticDataProperties: [
        { name: 'Alert', property: 'alert', direct: true },
        { name: 'Broken', property: 'broken', direct: true },
        { name: 'Client', property: 'client', direct: true },
        { name: 'Country', property: 'countryName', direct: true },
        { name: 'Parser', property: 'parser', direct: true },
        { name: 'Publisher', property: 'publisher', direct: true }
      ],
      sortDirection: 'asc',
      sortProperty: 'id',
      sortPropertyOptions: [
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = {
          alert: [{ id: true, name: 'true' }, { id: false, name: 'false' }],
          broken: [{ id: true, name: 'true' }, { id: false, name: 'false' }],
          client: [{ id: true, name: 'true' }, { id: false, name: 'false' }],
          countryName: [],
          parser: []
        }
        this.rows.forEach(source => {
          if (source.ok) {
            source.ok = moment(source.ok).format('YYYY-MM-DD')
          }
          if (source.frequency === 0) {
            source.frequency = 180
          }
          const maxdate = moment(source.maxdate)
          const today = moment()
          source.age = today.diff(maxdate, 'days')
          const ageAlert = source.age - source.frequency
          source.ageAlert = ageAlert
        })
        let countries = []
        _.each(this.rows, (row) => {
          if (row.countryName) {
            countries.push({ id: row.countryName, name: row.countryName })
          }
        })
        countries = _.uniqBy(countries, 'id')
        staticData.countryName = _.sortBy(countries, 'name')
        let publishers = []
        _.each(this.rows, (row) => {
          if (row.publisher) {
            publishers.push({ id: row.publisher, name: row.publisher })
          }
        })
        publishers = _.uniqBy(publishers, 'id')
        staticData.publisher = _.sortBy(publishers, 'name')
        _.each(this.rows, (row) => {
          if (row.ageAlert > 0) {
            row.alert = true
          } else {
            row.alert = false
          }
        })
        let parsers = []
        _.each(this.rows, (row) => {
          if (row.parser) {
            parsers.push({ id: row.parser, name: row.parser })
          }
        })
        parsers = _.uniqBy(parsers, 'id')
        staticData.parser = _.sortBy(parsers, 'name')
        this.staticData = staticData
        await this.$nextTick()
        console.log('finished loading')
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
      this.loading = false
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 200
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/sources/adminview/limit/${limit}/offset/${offset}`)
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
        console.log(this.rows)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
