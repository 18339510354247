<template>
<span>
  <b-nav-item-dropdown
    v-if="show"
    :text="text"
    toggle-class="nav-link-custom"
    right
  >
  <span v-for="link in links" :key="'refercendata' + link.model">
    <b-dropdown-item v-if="link.permission" :to="link.route">{{link.name}}</b-dropdown-item>
  </span>
</b-nav-item-dropdown>
</span>
</template>

<script>
import ac from '@/libs/accesscontrol'
export default {
  name: 'LinksDropdow',
  props: ['links', 'text'],
  computed: {
    show: function () {
      let result = false
      if (this.links) {
        if (this.links.length > 0) {
          this.links.forEach(x => {
            if (x.permission) {
              result = true
            }
          })
        }
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    if (this.links) {
      if (this.links.length > 0) {
        this.links.forEach(x => {
          x.permission = ac.can(this.user.acgroups).readAny(x.model).granted
        })
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
